import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';


export default function Login() {
    let [searchParams] = useSearchParams();
    const invitation = searchParams.get('invitation') ?? undefined
    const organization = searchParams.get('organization') ?? undefined;
    const organization_name = searchParams.get('organization_name') ?? undefined;

    const {
        isAuthenticated,
        loginWithRedirect,
    } = useAuth0();

    if (!isAuthenticated) loginWithRedirect({ authorizationParams: { invitation, organization, organization_name } })

    return (
        <></>
    )
}