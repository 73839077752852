import React, { Suspense } from 'react';
import './App.css';
import { Auth0Provider } from '@auth0/auth0-react';
import Login from './components/Login';

function App() {
  return (
    <Suspense>
      <Auth0Provider
        clientId={process.env.REACT_APP_CLIENT_ID ?? ``}
        domain={process.env.REACT_APP_DOMAIN ?? ``}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/callback`
        }}
      >
        <Login />
      </Auth0Provider>
    </Suspense>
  );
}

export default App;
