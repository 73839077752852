import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function LoginCallback() {
    const { t } = useTranslation();

    let [searchParams] = useSearchParams();

    const error = searchParams.get('error')

    return (
        error ? <p>{t('errorMessage')}</p> : <SuccessMessage />
    )
}

function SuccessMessage() {
    const { t } = useTranslation();

    return (
        <>
            <h1>{t('successTitle')}</h1>
            <p>{t('successMessage')}</p>
            <p>{t('successCta')}</p>
            <a href="https://apps.apple.com/us/app/1komma5-service-app/id6469031692">IOS</a >
            <a href="https://play.google.com/store/apps/details?id=io.onecommafive.service.production.app">Android</a>
        </>
    )
}